<template>
  <div>
    <div
      class="h-100 d-flex align-items-center"
      :class="{ 'image-wrapper-active': overShare }"
      @mouseover="overShare = true"
      @mouseleave="overShare = false"
      @click="[(isShow = !isShow), (searchedTextValue = '')]"
      v-if="!isShow"
    >
      <div v-if="overShare" class="tooltip-wrapper">Search</div>
      <img
        v-if="overShare"
        src="@/assets/Icons/searchWhite.svg"
        class="image"
      />
      <img v-else src="@/assets/Icons/searchDark.svg" class="image" />
    </div>
    <div v-show="isShow">
      <b-input-group class="">
        <b-input-group-prepend
          @click="[(isShow = !isShow), (searchedTextValue = '')]"
        >
          <img src="@/assets/Icons/searchDark.svg" class="image" />
        </b-input-group-prepend>
        <b-form-input
          class="search"
          :placeholder="placeHolder"
          @keyup="clickedSearch"
          v-model="searchedTextValue"
        ></b-form-input>
      </b-input-group>
    </div>
  </div>
</template>

<script>
let timeout;
export default {
  props: {
    placeHolder: {
      type: String,
      default: "Search By Name",
    },
  },
  data() {
    return {
      overShare: false,
      searchedTextValue: "",
      isShow: false,
    };
  },
  methods: {
    clickedSearch(event) {
      clearTimeout(timeout);
      this.searchedTextValue = event.target.value.toLowerCase();
      var self = this;
      if (self.searchedTextValue.length !== 0) {
        timeout = setTimeout(function() {
          self.$emit("searchText", self.searchedTextValue);
          clearTimeout(timeout);
        }, 3000);
      } else {
        self.$emit("searchText", "");
      }
    },
  },
};
</script>
<style>
.input-group-prepend {
  background-color: #fff !important;
}
.search.form-control {
  border: none;
  border-color: #c6ccd6;
  margin-left: 0;
  background-color: #fff;
  box-shadow: none;
  height: 60px !important ;
  font-size: 16px;
}
input::placeholder {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #9aafd4;
}
.search.form-control:focus {
  background-color: #fff !important;
  outline: none !important;
  box-shadow: none !important;
  border: 0px !important;
}
</style>
<style scoped>
.image {
  padding: 16px 16px;
}
.image-wrapper-active {
  position: relative;
  background-color: #050505;
}
.tooltip-wrapper {
  width: 80px;
  height: 56px;
  background-color: #050505;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  clip-path: polygon(100% 0, 100% 53%, 50% 86%, 0 53%, 0 0);
  padding-top: 4px;
}
</style>
