<template>
  <div class="page-wrapper chiller-theme" style="margin-top: 71px">
    <main class="page-content">
      <Toast
        v-if="toastData.show"
        :type="toastData.type"
        :message="toastData.message"
        :show="toastData.show"
        :time="10000"
        @clearToast="clearToast"
      />
      <div class="container-fluid">
        <ul class="breadcrumb ProximaNovaRegular" style="margin-top: 12px">
          <li class="ProximaNovaBold">
            Settings
          </li>
          <li class="ProximaNovaBold">
            Users
          </li>
        </ul>
      </div>
      <div class="container-fluid">
        <w-button
          :buttonText="'+ Add User '"
          :buttonLoader="addUserLoader"
          class="ml-3"
          @buttonClicked="getAllDomainList('user')"
          :isDisable="addUserLoader === 'loader' ? true : false"
          :class="{
            resetPasswordLink: addUserLoader === 'loader' ? true : false,
          }"
        ></w-button>
        <w-button
          :buttonText="'Brand Summary'"
          :buttonLoader="'none'"
          @buttonClicked="$router.push('/settings/brands')"
        ></w-button>
        <w-button
          class="mr-3"
          :buttonText="'+ Add Brand'"
          :buttonLoader="addBrandLoader"
          @buttonClicked="getAllDomainList('brand')"
          :isDisable="addBrandLoader === 'loader' ? true : false"
          :class="{
            resetPasswordLink: addBrandLoader === 'loader' ? true : false,
          }"
        ></w-button>
      </div>
      <div class="container-fluid m-0 p-0 mt-5">
        <div class="m-5 content-center" style="justify-content: space-between;">
          <h2 class="ProximaNovaBold">User List</h2>
          <w-search @searchText="searchEvent($event)"></w-search>
        </div>
        <div class="mx-5 text-right" style="font-size: small;">
          <div>
            {{ rowsPrePage * currentPage - 4 }} -
            {{ rowsPrePage * (currentPage - 1) + tableDataValues.items.length }}
            of {{ totalRows }}
          </div>
        </div>
        <div class="m-5">
          <w-table
            :tableProperties="tableDataValues"
            :noDataText="'No User has been created yet'"
            @sortField="sortByFieldName($event)"
            @deleteUser="deleteUserHandler"
            @editRow="editUserHandler"
            @resentEmail="resendOtp($event)"
          />
          <div class="d-flex" style="justify-content: end;">
            <w-pagination
              :currentPage="currentPage"
              :perPage="rowsPrePage"
              :totalRows="totalRows"
              @getSelectedPageEvent="getSelectedPageEvent($event)"
            />
          </div>
        </div>
      </div>
      <AddUserModal ref="addUserModal" @createUser="addUserDetails($event)" />
      <AddBrandModal
        ref="addBrandModal"
        @createBrand="addBrandDetails($event)"
      />
      <DeleteUserModal
        ref="deleteUserModalBox"
        @deleteUser="deleteUserHandlerEvent"
      />
      <EditUserModal ref="editUserModal" @updateUser="updateUser($event)" />
    </main>
  </div>
</template>

<script>
import SearchBar from "@/widgets/Search.vue";
import DataTable from "@/widgets/DataTable.vue";
import { UserManagement } from "@/services/UserManagementService";
import Pagination from "@/widgets/Pagination.vue";
import Button from "@/components/Profile/Button.vue";
import AddUserModal from "@/components/Modal/AddUserModal.vue";
import EditUserModal from "@/components/Modal/EditUserModal.vue";
import AddBrandModal from "@/components/Modal/AddBrandModal.vue";
import DeleteUserModal from "@/components/Modal/DeleteUserModal.vue";
import Toast from "@/components/Toast/Toast.vue";
import { LoginServices } from "@/services/LoginServices.js";
const loginServices = new LoginServices();
const userManagement = new UserManagement();
export default {
  components: {
    "w-search": SearchBar,
    "w-table": DataTable,
    "w-pagination": Pagination,
    "w-button": Button,
    AddUserModal,
    AddBrandModal,
    DeleteUserModal,
    EditUserModal,
    Toast,
  },
  data() {
    return {
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      freeDomainList: [],
      rowsPrePage: 5,
      totalRows: 0,
      currentPage: 1,
      tableDataValues: {
        fields: [
          {
            key: "userName",
            label: "Full Name",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: true,
          },
          {
            key: "email",
            label: "Email Id",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "role",
            label: "User Role",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: true,
          },
          {
            key: "createdDate",
            label: "Created Date",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: true,
          },
          {
            key: "toolsAccess",
            label: "Tools",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "verification",
            label: "Verification",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "status",
            label: "Status",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "assignedBrands",
            label: "Brand",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "action",
            label: "Action",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
        ],
        items: [],
      },
      sortFieldName: "",
      sortFieldDirection: "",
      searchedTextValue: "",
      avilableBrand: [],
      editUserData: {},
      addUserLoader: "none",
      addBrandLoader: "none",
    };
  },
  mounted() {},
  methods: {
    resendOtp(e) {
      loginServices.resendOtp(e.email).then(() => {
        this.toastData.show = true;
        this.toastData.type = "success";
        this.toastData.message = "A Email has been sent to the " + e.email;
      });
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    searchEvent(event) {
      this.searchedTextValue = event;
      if (event !== "") {
        this.currentPage = 1;
        this.getSearchedTable(event);
      } else {
        this.getUserList();
      }
    },
    editUserHandler(e) {
      this.editUserData = e;
      var roles = [];
      var toolsData = [];
      var allTools = JSON.parse(sessionStorage.getItem("tools"));
      for (var i = 0; i < allTools.length; i++) {
        toolsData.push({ id: allTools[i], label: allTools[i] });
      }
      if (sessionStorage.getItem("Role").toLowerCase() === "admin") {
        roles = [
          {
            text: "User",
            id: "user",
          },
        ];
      } else {
        roles = [
          {
            text: "Admin",
            id: "admin",
          },
          {
            text: "User",
            id: "user",
          },
        ];
      }
      var selectedTools = [];
      selectedTools = this.editUserData.subscriberToolType;
      var selectedBrand = [];
      selectedBrand = this.editUserData.brandIds;
      var avilableBrand = [];
      userManagement.getAllBrandList().then((res) => {
        for (var i = 0; i < res.response.length; i++) {
          avilableBrand.push({
            id: res.response[i].id,
            label: res.response[i].brandName,
          });
        }
        this.$refs.editUserModal.open({
          title: "Edit User",
          data: this.editUserData,
          role: roles,
          tools: toolsData,
          selectedTools: selectedTools,
          selectedBrand: selectedBrand,
          brand: avilableBrand,
        });
      });
    },
    deleteUserHandler(event) {
      this.$refs.deleteUserModalBox.open({
        title: "Delete User",
        userId: event.id,
        userName: event.userName,
      });
    },
    updateUser(e) {
      userManagement.updateUser(e).then((res) => {
        console.log(res);
        this.getUserList();
        this.$refs.editUserModal.hideWelcomePage();
      });
    },
    deleteUserHandlerEvent(event) {
      userManagement.deleteUser(event).then((res) => {
        console.log(res);
        this.$refs.deleteUserModalBox.hideWelcomePage();
        this.getUserList();
      });
    },
    addBrandDetails(data) {
      userManagement.createNewBrand(data).then((res) => {
        console.log(res);
        this.$refs.addBrandModal.hideWelcomePage();
      });
    },
    getSearchedTable(data) {
      userManagement
        .getsearchUserName(
          data,
          this.currentPage,
          this.rowsPrePage,
          sessionStorage.getItem("clientId")
        )
        .then((res) => {
          this.tableDataValues.items = [];
          this.tableDataValues.items = res.content;
          this.totalRows = res.totalElements;
        });
    },
    getSelectedPageEvent(event) {
      if (this.searchedTextValue !== "") {
        this.currentPage = event;
        this.getSearchedTable(this.searchedTextValue);
      } else {
        if (event != this.currentPage) {
          this.currentPage = event;
          if (this.sortFieldDirection === "" && this.sortFieldName === "") {
            this.getUserList();
          } else {
            this.getSortedTable();
          }
        }
      }
    },
    getUserList() {
      userManagement
        .getAllUserByClient(
          sessionStorage.getItem("clientId"),
          this.currentPage,
          this.rowsPrePage
        )
        .then((res) => {
          this.tableDataValues.items = [];
          this.tableDataValues.items = res.response.content;
          this.totalRows = res.response.totalElements;
        });
    },
    sortByFieldName(event) {
      this.sortFieldName = event.sortBy;
      this.sortFieldDirection = event.sortDesc ? "ASC" : "DESC";
      if (
        event.sortBy == "userName" ||
        event.sortBy == "role" ||
        event.sortBy == "createdDate"
      ) {
        this.currentPage = 1;
        this.getSortedTable();
      }
    },
    getSortedTable() {
      userManagement
        .sortedField(
          {
            field: this.sortFieldName,
            direction: this.sortFieldDirection,
            email: sessionStorage.getItem("clientId"),
          },
          this.currentPage,
          this.rowsPrePage
        )
        .then((res) => {
          this.tableDataValues.items = [];
          this.tableDataValues.items = res.content;
          this.totalRows = res.totalElements;
        });
    },
    addUserDetails(event) {
      userManagement.registerUser(event).then((res) => {
        console.log(res);
        this.$refs.addUserModal.hideWelcomePage();
        this.toastData.show = true;
        this.toastData.type = "success";
        this.toastData.message =
          "A email has been sent to the " + event.email;
        this.getUserList();
      });
    },
    addUserHandler(type) {
      if (type === "user") {
        this.addUserLoader = "loader";
      } else if (type === "brand") {
        this.addBrandLoader = "loader";
      }
      var allTools = JSON.parse(sessionStorage.getItem("tools"));
      var roles = [];
      if (sessionStorage.getItem("Role").toLowerCase() === "admin") {
        roles = [
          {
            text: "User",
            id: "user",
          },
        ];
      } else {
        roles = [
          {
            text: "Admin",
            id: "admin",
          },
          {
            text: "User",
            id: "user",
          },
        ];
      }
      var toolsData = [];
      for (var i = 0; i < allTools.length; i++) {
        toolsData.push({ id: allTools[i], label: allTools[i] });
      }
      userManagement.getAllBrandList().then((res) => {
        this.addUserLoader = "none";
        this.addBrandLoader = "none";
        console.log(res);
        var brands = [];
        for (var i = 0; i < res.response.length; i++) {
          brands.push({
            id: res.response[i].id,
            label: res.response[i].brandName,
          });
        }
        if (type === "user") {
          this.$refs.addUserModal.open({
            title: "Add User",
            role: roles,
            tools: toolsData,
            brand: brands,
            domailList: this.freeDomainList
          });
        } else if (type === "brand") {
          this.getBrands(roles, toolsData, brands);
        }
      });
    },
    getAllDomainList(type) {
      if (type === "user") {
        userManagement.getDomainList().then((res) => {
          this.freeDomainList = res.response;
          this.addUserHandler(type);
        });
      } else {
        this.addUserHandler(type);
      }
    },
    getBrands(roles, toolsData, brands) {
      userManagement.getAllBrandList().then((res) => {
        for (var i = 0; i < res.response.length; i++) {
          this.avilableBrand.push(res.response[i].brandName.toLowerCase());
        }
        this.$refs.addBrandModal.open({
          title: "Add Brand",
          role: roles,
          tools: toolsData,
          brand: brands,
          allBrand: this.avilableBrand,
        });
      });
    },
  },
  created() {
    this.getUserList();
  },
};
</script>

<style></style>
